
@keyframes load {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.loading_wrap {
    /* height: calc(100vh - 500px); */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: load 1s;
}

.loading_box {
    width: 250px;
    height: 50px;
}

.loading_img {
    background-color: transparent;
    width: 100%;
    height: 100%;
    /* scale: 0.5; */
    border: none;
    background: url(../../img/common/ico_load_2.svg) no-repeat center
}