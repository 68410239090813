.notfound{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.notfound_wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 60px;
    border-radius: 10px;
    box-shadow: 0 1px 30px tomato;
}

.notfound_wrap h1{
    margin-bottom: 10px;
    color: tomato;
    font-weight: 700;
    font-size: 2rem;
}

.notfound_wrap button{
    width: 100%;
    padding: 10px 20px;
    margin: 20px 20px;
    font-weight: 700;
    font-size: 2rem;
    border: 1px solid transparent;
}
