/**************************************************************************************************************
	Navigate link box page styles
**************************************************************************************************************/
.active .navi_box { 
	opacity:1; 
	top:65px; 
	left: 165px; 
	visibility: visible; 
}
.navi_box { 
	position:absolute; 
	opacity:0; 
	top:65px; 
	left:165px; 
	visibility: hidden;
	border-radius:10px; 
	box-shadow:5px 5px 15px rgba(0,0,0,0.15); 
	z-index:100; 
	padding:10px 0px; 
	background-color: #fff; 
	min-width:160px;  
	transition: all 0.5s ease; 
}
.navi_box ul li button {
	padding: 10px 15px; 
	width: 100%;
}
.navi_box ul li button:hover {
	background-color:var(--background-color4);
	color: var(--point-color1);
}
.subject {
	margin: 0px 10px;
	padding: 0px 15px;
	min-width: 160px;
	border-radius: 5px;
	cursor: pointer; 
	text-align: center; 
	line-height: 45px;
	color: var(--font-color3);
}
.subject i {margin-left: 10px;}
.subject:hover {background-color: var(--background-color2); color:var(--font-color3);}
.active .subject {background-color: var(--background-color2); color: var(--font-color3);}
