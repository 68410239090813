.frame{
    padding-top: 60px;
    width: 100%;
}

.side_content_wrap{
    display: flex;
    transition: 0.3s;
}

@media screen and (max-width:480px){
    .side_content_wrap{
        flex-direction: column;
    }
}