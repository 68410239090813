@keyframes smoothUp {
	from {
		opacity: 0;
		transform: translateY(5%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes smoothDown {
	from {
		opacity: 0;
		transform: translateY(-5%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.content{
    width: 100vw;
}

.navContent {
    width: calc(100vw - 200px);
}

.content_wrap{
    height: calc(100vh - 60px);
    padding : 1.3vw;
    background-color: #fefefe;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.content_wrap div{
    animation: smoothDown 0.5s;
}

@media screen and (max-width: 1023px) {
    .navContent{
        width: calc(100vw - 75px);
        padding: 15px;
    }
}

@media screen and (max-width:480px){
    .navContent{
        width: 100vw;
        padding: 10px;
    }
}