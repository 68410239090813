.ZD420 {
    width: 100mm;
    height: 60mm;
}
.ZD420 .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
}
.ZD420 .header .comlab_name {
    width: 30mm;
}
.ZD420 .header .dispatch_date {
    font-size: 16px;
}
.ZD420 .body {
    display: flex;
    gap: 10px;
    width: 100%;
}
.ZD420 .body .info {
    display: inline-block;
    text-align: left;
    width: 100%;
    white-space: break-spaces;
    font-size: 12px;
}
.ZD420 .body .info .font1 {
    font-size: 28px;
}
.ZD420 .body .info .font2 {
    font-size: 20px;
}



.LTM10 {
    width: 90mm;
    height: 45mm;
    margin : 0mm 0mm 0mm 40mm;
}
.LTM10 .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    margin-bottom: 5px;
}
.LTM10 .header .comlab_name {
    font-size: 12px;
}
.LTM10 .header .dispatch_date {
    font-size: 12px;
}
.LTM10 .body {
    display: flex;
    gap: 10px;
    width: 100%;
}
.LTM10 .body .info {
    display: inline-block;
    text-align: left;
    width: 100%;
    white-space: break-spaces;
    font-size: 12px;
}
.LTM10 .body .info .font1 {
    font-size: 16px;
}
.LTM10 .body .info .font2 {
    font-size: 14px;
}


.ZD230 {
    width: 100mm;
    height: 40mm;
    margin : 0mm 0mm 10mm 15mm;
}
.ZD230 .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    margin-bottom: 5px;
}
.ZD230 .header .comlab_name {
    flex: 0 0 auto;
    width: 40mm;
    overflow: hidden;
    font-size: 12px;
}
.ZD230 .header .dispatch_date {
    flex: 0 0 auto;
    width: 40mm;
    font-size: 12px;
}
.ZD230 .body {
    display: flex;
    gap: 10px;
    /* width: 120mm;
    height: 80mm; */
}
.ZD230 .qrcod {
    flex: 0 0 auto;
    width: 35mm;
}
.ZD230 .body .info {
    display: inline-block;
    text-align: left;
    width: 45mm;
    height: 40mm;
    white-space: break-spaces;
    font-size: 12px;
    overflow: hidden;
}
.ZD230 .body .info .font1 {
    font-size: 16px;
}
.ZD230 .body .info .font2 {
    font-size: 14px;
}