.pagenate { text-align: center; padding-bottom: 10px; }
.pagenate div { display: inline-block; vertical-align: middle; }
.pagenate button { 
	display: inline-block; 
	vertical-align: middle; 
	text-align: center; 
	line-height: 34px; 
	width:36px; 
	height: 36px; 
	margin:0 2px;
	font-size:16px;  
	color: #718096;  
	font-weight: 600; 
	border: 1px solid transparent;
	border-radius:5px; 
	background-color: var(--background-color1);
	cursor: pointer;
	margin: 0px 5px;
}
.pagenate button.active { font-weight: 600; color:#fff; background:var(--point-color1); }
.pagenate button.first,
.pagenate button.last{background: url(../../img/admin/ico_page_first.svg) no-repeat; }
.pagenate button.prev,
.pagenate button.next{background: url(../../img/admin/ico_page_prev.svg) no-repeat; }
.pagenate button.next { transform: rotate(180deg);}
.pagenate button.last { transform: rotate(180deg);}

/**************************************************************************************************************
	Pagination Short
**************************************************************************************************************/
.pagenate_short {height: 45px; display: flex; align-items: center; border: 1px solid var(--line-color1); border-radius: 5px; color: var(--font-color2);}
.pagenate_short .colum {padding: 5px 10px;}
.pagenate_short .move:hover {color: var(--point-color1); cursor: pointer;}