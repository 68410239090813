/**************************************************************************************************************
	language select styles
**************************************************************************************************************/
.active .lang_box { 
	opacity:1; 
	top:60px; 
	right: 230px; 
	visibility: visible; 
}
.lang_box { 
	position:absolute; 
	opacity:0; 
	top:100px; 
	right:230px; 
	visibility: hidden;
	border-radius:10px; 
	box-shadow:5px 5px 15px rgba(0,0,0,0.15); 
	z-index:100; 
	padding:10px 0px; 
	background-color: #fff; 
	min-width:100px;  
	transition: all 0.5s ease; 
}
.lang_box ul li button {
	padding: 10px 15px; 
	width: 100%;
}
.lang_box ul li button:hover {
	background-color:var(--point-color1);
	color: #fff;
}
.icon {
	margin: 0px 10px;
	cursor: pointer; 
	text-align: center; 
	line-height: 45px; 
	color: var(--font-color3);
}
.icon:hover {color: var(--point-color1);}
.active .icon {color: var(--point-color1);}
