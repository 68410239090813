.header { 
    position: fixed;
    top: 0; 
    left: 0; 
    width: 100vw; 
    background-color: #fff; 
    box-shadow: 0 0 3px #000; 
    z-index: 20; 
}

.header_wrap { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 16px;
}

.header_start {
    display: flex;
    height: 40px;
    line-height: 40px;
    width: 400px;
}

.header_logo {
    width: 100px;
    cursor: pointer;
}

.header_navi {
    border: none;
    width: 40px;
    height: 40px;
    background: url(../../img/common/ico_top_menu.png) no-repeat center
    
}

.active,
.header_navi:hover {
    background: url(../../img/common/ico_top_menu_on.png) no-repeat center
}

.header_navi_select {
    margin-left: 15px;
    line-height: 40px;
}

.header_navi_select select {
    padding: 5px;
    cursor: pointer;
}

.header_center {
    width: 40vw; 
    padding: 3px 15px; 
    background-color: #EEF5FF; 
    color: #4C90F6; 
    border-radius: 5px; 
    font-size: 14px; 
    overflow: hidden;
    white-space: nowrap;
}

.header_end {
    display: flex;
    height: 40px;
    line-height: 40px;
    gap: 10px;
    margin-right: 10px;
}

.header_profil_img {
    width: 40px;
    height: 40px;
    background: url(../../img/common/ico_top_user.png) no-repeat 50% 50%;
    cursor: pointer;
}

.header_profil_img.active,
.header_profil_img:hover {
    border-radius: 50%;
    box-shadow: 1px 1px 3px var(--line-color3);
}

.header_profil_img .active {
    opacity:1; top:44px; visibility: visible; 
}

.header_profil_labname {
    background-color: #EEF5FF;
    padding: 0px 10px;
    border-radius: 5px;
    color: #001b64;
    min-width: 50px;
    overflow: hidden;
}

.header_profil_name {
    font-size: 1.7rem;
    margin-left: 6px;
    min-width: 65px;
}

.logout {
    color: #999;
}

.logout:hover {
    font-weight: 700;
    color: #4C90F6
}

/* Mobil */
@media screen and (max-width: 1023px) {
    .header_profil_name {
        display: none;
    }
    .header_title {
        display: none;
    };
}

/* PC */
@media screen and (min-width: 480px) {
}