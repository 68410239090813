.ip_p {
    margin: 20px 0px;
    position: relative;
    display: inline-block;
    width: 100%;
}

.ip_label {
    padding: 10px 20px;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.7rem;
    transition: 0.1s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 0.1);
    opacity: 0.5;
}

.ip_input {
    padding: 10px 10px 10px 20px;
    width: 100%;
    font-size: 1.7rem;
    border: 1px solid var(--line-color1);
    border-radius: 5px;
}

.ip_input:focus{
    border: 1px solid var(--line-color3);
}

.ip_input:focus + .ip_label,
.ip_input:not(:placeholder-shown) + .ip_label {
    opacity: 1;
    background: #fff;
    transform: scale(1) translateY(-70%) translateX(10px);
    padding: 5px 20px;
}