.h1 { font-size: calc(1.375rem + 1.5vw); font-weight: 800 !important;}
.h3 { font-weight: 700 !important;}
.h4 { font-weight: 700 !important;}
.h5 { font-size: 1.25rem;}
.policy_wrap {
    width: 100vw;
    font-size: 18px;
    color: #6B6A75;
    margin: 0 auto;
}
.container {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.section_title {
    max-width: 1000px;
    text-align: center;
}
.section_title_policy {
    font-weight: 800 !important;
    color: #06A3DA !important;
    font-size: 2.5rem;
}
.border_bottom {
    border-bottom: 1px solid #dee2a6 !important;
}
.p_3 {
    padding :1.5rem !important;
}
.p_2 {
    padding :1.0rem !important;
}

.mt_2{
    margin-top: 3.0rem !important;
}
.mt_4{
    margin-top: 1.5rem !important;
}
.col_md_6{
    display: inline-flex;
    width: 50%;
}
.bold{
    font-weight: 700;
}
.numberTitle {
    display: block;
    line-height: 28px;
    color: #1e1e1e;
    font-size: 18px;
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}
.sub_text {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}
.policy_title_h2 {
    display: block;
    padding-top: 1rem;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: #1e1e1e;
    margin-bottom: 10px;
}

.policy_p {
    margin-bottom: 8px;
    line-height: 1.5;
    color: #666;
    font-size: 16px;
}
.article {
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
}
.mt_4 {
    margin-top: 1.5rem !important;
}
.hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25;
}
.table{
    --bs-table-bg: rgba(0,0,0,0);
    --bs-table-striped-color: #6B6A75;
    --bs-table-striped-bg: rgba(0,0,0,0.05);
    --bs-table-active-color: #6B6A75;
    --bs-table-active-bg: rgba(0,0,0,0.1);
    --bs-table-hover-color: #6B6A75;
    --bs-table-hover-bg: rgba(0,0,0,0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #6B6A75;
    vertical-align: top;
    border-color: #dee2e6;
    caption-side: bottom;
    border-collapse: collapse;
    text-align: left;
}
.table > tbody {
    vertical-align: inherit;
}
.table > tbody > tr {
    border-bottom: 1px solid #dee2e6;
}
.sub_list {
    margin-bottom: 8px;
    line-height: 1.5;
    color: #666;
    font-size: 14px;
}