.btn{
    margin-top: 15px
}

.btn_wrap{
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn_button{
    width: 100%;
    padding: 10px 20px;
    font-size: 1.5em;
    font-weight: 700;
    color: #f8f8f8;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: var(--point-color1);
    cursor: pointer;
    box-shadow: 1px 3px 3px var(--point-color3); 
}

.btn_button:hover{
    /* box-shadow: 0px 0px 3px var(--point-color3);  */
}

.btn_button:active{
    transform: translateY(2px);
    box-shadow: 0px 0px 3px var(--point-color3); 
}