.radio_item .radio { position: absolute; left: -9999px; }
.radio_item .radio:checked + span { background-color: #bcd7ff;}
.radio_item .radio:checked + span:before { box-shadow: inset 0 0 0 0.4375em var(--point-color1);}
.radio_item .radio:disabled + span { background-color: var(--background-color2)}
.radio_item .radio:disabled + span:before { background: var(--background-color2); border-color:var(--line-color2); }

.radio_item span { display: flex; align-items: center; padding: 0.375em 0.75em 0.375em 0.375em; border-radius: 99em; transition: 0.25s ease; }
.radio_item span:hover { background-color: #bcd7ff}
.radio_item span:before { 
	display: flex; 
	flex-shrink: 0; 
	content: ""; 
	background-color: #fff;
	width: 1.5em; 
	height: 1.5em; 
	border-radius: 50%; 
	margin-right: 0.375em; 
	transition: 0.25s ease; 
	box-shadow: inset 0 0 0 0.125em var(--point-color1); 
}