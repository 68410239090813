.navi{
    width: 0px;
    opacity: 0;
    transition: 0.3s;
    cursor: default;
    pointer-events: none;
}

.navi_wrap{
    position: fixed;
    top: 60px;
    left: 0; 
    height: calc(100vh - 60px);
    transition: 0.3s;
}

.active {
    flex: 0 0 auto;
    width: 200px;
    transition: 0.3s;
    overflow: auto;
}

.navi_wrap li a{
    display: flex;
    padding: 7px 20px 7px 10px;
    cursor: pointer;
    text-decoration: none;
}

.navi_wrap li button {
    flex: 0 0 auto;
    border: none;
    background-color: transparent;
    height: 45px;
    width: 45px;
    padding-right: 20px;
}

.navi_name{
    color: var(--font-color1);
    font-size: 16px;
    line-height: 40px;
    max-width: 130px;
}

.navi_wrap li button.m1 {background: url(../../img/common/ico_nav1_off.png) no-repeat center;}
.navi_wrap li button.m2 {background: url(../../img/common/ico_nav2_off.png) no-repeat center;}
.navi_wrap li button.m3 {background: url(../../img/common/ico_nav3_off.png) no-repeat center;}
.navi_wrap li button.m4 {background: url(../../img/common/ico_nav4_off.png) no-repeat center;}
.navi_wrap li button.m5 {background: url(../../img/common/ico_nav5_off.png) no-repeat center;}
.navi_wrap li button.m6 {background: url(../../img/common/ico_nav6_off.png) no-repeat center;}
.navi_wrap li button.m7 {background: url(../../img/common/ico_nav7_off.png) no-repeat center;}
.navi_wrap li button.m8 {background: url(../../img/common/ico_nav8_off.png) no-repeat center;}
.navi_wrap li button.m9 {background: url(../../img/common/ico_nav9_off.png) no-repeat center;}
.navi_wrap li button.m10 {background: url(../../img/common/ico_nav10_off.png) no-repeat center;}
.navi_wrap li button.m11 {background: url(../../img/common/ico_nav11_off.png) no-repeat center;}

.navi_wrap li.active,
.navi_wrap li:hover{
    background-color: #eff4fc;
}
.navi_wrap li.active p {
    color: #4C90F6;
}

.navi_wrap li.active button.m1 {background: url(../../img/common/ico_nav1_on.png) no-repeat center;}
.navi_wrap li.active button.m2 {background: url(../../img/common/ico_nav2_on.png) no-repeat center;}
.navi_wrap li.active button.m3 {background: url(../../img/common/ico_nav3_on.png) no-repeat center;}
.navi_wrap li.active button.m4 {background: url(../../img/common/ico_nav4_on.png) no-repeat center;}
.navi_wrap li.active button.m5 {background: url(../../img/common/ico_nav5_on.png) no-repeat center;}
.navi_wrap li.active button.m6 {background: url(../../img/common/ico_nav6_on.png) no-repeat center;}
.navi_wrap li.active button.m7 {background: url(../../img/common/ico_nav7_on.png) no-repeat center;}
.navi_wrap li.active button.m8 {background: url(../../img/common/ico_nav8_on.png) no-repeat center;}
.navi_wrap li.active button.m9 {background: url(../../img/common/ico_nav9_on.png) no-repeat center;}
.navi_wrap li.active button.m10 {background: url(../../img/common/ico_nav10_on.png) no-repeat center;}
.navi_wrap li.active button.m11 {background: url(../../img/common/ico_nav11_on.png) no-repeat center;}

@media screen and (max-width: 1023px) {
    .navi_name {
        display: none;
    }
    
    .navi_wrap{
        min-width: 0px;
    }

    .active{
        width: 75px;
    }
}

@media screen and (max-width:480px){
    .navi{
        height: 0px;
    }
    .active{
        width: 100%;
    }
    .navi_wrap{
        height: auto;
        display: flex;
        flex-direction: column;
        overflow: auto;

        position: relative;
        top: 0px;
    }

    .navi_name{
        display: block;
    }
}