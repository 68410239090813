.login{
}

.login_wrap{
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: space-between;
    padding: 1vw 3vw;
    gap: 20px;
    transition: 0.5s linear;
}


.login_start{
    flex: 1;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    max-width: 100%;
    padding: 10px;
}

.login_descript h2{
    font-size: 40px;
    font-weight: 700;
    margin: 25px 0px;
    transition: 0.5s linear;
}

.login_descript p{
    font-size:16px; 
    color:var(--font-color3); 
    line-height:1.5; 
}

.login_end{
    flex: 0 0 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 120px 70px;
    box-shadow: 0 1px 40px #d9e8ff;
    transition: 0.5s linear;
}

.login_form{
    display: flex;
    padding: 1rem;
    max-width: 100%;
    flex-direction: column;
}

.login_form h1{
    font-size: 3.0rem;
    font-weight: 700;
    margin-bottom: 1rem;

}

@media screen and (max-width: 1023px){
    .login_wrap{
        flex-direction: column;
        justify-content: center;
    }
    .login_start {
        max-height: 200px;
    }
    .login_descript{
        max-width: 600px;
        margin-bottom: 1rem;
    }
    .login_descript h2{
        font-size: 4vw;
    }
    .login_end{
        flex: 0 0 500px;
        padding: 10vw 10vw;
    }
    .login_form{
        width: 100%;
    }
}

@media screen and (max-width:480px){
    .login_wrap{
        width: 100vw;
        justify-content: start;
    }
    .login_start {
        width: 100%;
        margin-top: 20px;
    }
    .login_descript{
        width: 100%;
    }
    .login_descript h2{
        font-size: 20px;
        font-weight: 700;
        margin: 25px 0px;
    }
    
    .login_descript p{
        font-size:16px; 
        color:var(--font-color3); 
        line-height:1.5; 
    }
    .login_end {
        height: 30vh;
    }
}