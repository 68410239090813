.page {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.item_frame {
    width: 100%;
    padding: 20px;
}

.item_frame h3 {
    font-size: 24px;
    font-weight: 500;
    padding: 10px 0px;
    border-bottom: 1px solid #d1d1d1;
}