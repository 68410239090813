.header{
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    /* background-color: var(--background-color3); */
    margin-bottom: 1.5vw;
    border-bottom: 1px solid #4C90F6;
}

.header_wrap{
    padding: 15px 15px 15px 2vw;
    font-size: 1.7em;
    color : #4C90F6;
}

.blue{
    background-color: var(--background-color4);
}

@media screen and (max-width: 1023px) {
    .header_wrap {
        padding: 10px 10px 10px 3vw;
        font-size: 1.5em;
    }
}

@media screen and (max-width:480px){
    .header_wrap{
        padding: 7px 7px 7px 3vw;
        font-size: 1.2em;
    }
}