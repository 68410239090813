@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Roboto:wght@100;300;500;700;900&display=swap');
/* @import url('https://cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css'); */
/* @import url('xeicon/xeicon.min.css'); */
/**************************************************************************************************************
	변수
**************************************************************************************************************/
:root { 
	--font-color1: #000;
	--font-color2: #555;
	--font-color3: #777;
	--font-color4: #999;
	--background-color1: #fefefe;
	--background-color2: #f9f9f9;
	--background-color3: #eee;
	--background-color4: #F0F6FF;
	--background-color5: #EEF5FF;
	--background-color6: #fde4e4;
	--line-color1: #d1d1d1;
	--line-color2: #d4d4d8;
	--line-color3: #4C90F6;
	--line-color4: #ddd;
	--line-color5: #001b64;
	--point-color1:#4C90F6;
	--point-color2:#040404;
	--point-color3:#545871;
	--point-color4:#D9E8FF;
	--state-color1:#e99c00;
	--state-color2:#de70a7;
	--state-color3:#a25fbc;
	--state-color4:#59647d;
	--state-color5:#ff5353;
	--state-color6:#a2bff4;
	--state-color7:#778899;
	--state-color8:#7B68EE;
}

/**************************************************************************************************************
	Common
**************************************************************************************************************/
html,body,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,address,big,cite,code,del,dfn,em,font,img,ins,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,ul,ol,li,dl,dt,dd,table,caption,tbody,tfoot,thead,tr,th,td,fieldset,fo7m,label,legend,input,button,textarea,select{margin:0;padding:0}
header,footer,section,article,aside,nav,hgroup,details,menu,figure,figcaption{display:block;margin:0;padding:0}
legend{display:block;overflow:hidden;position:absolute;width:1px;height:1px;font-size:1px;line-height:0;text-indent:-999em;white-space:nowrap; }
fieldset,img,table,caption,tbody,tfoot,thead,tr,th,td,button,hr{border:0 none}
img{vertical-align:middle; }
body li{vertical-align:top}
ul, li, ol{list-style:none; padding:0; margin:0;}
table{border-collapse:collapse}
caption{visibility:hidden;overflow:hidden;width:0;height:0;font-size:0;line-height:0;}
button{overflow:visible;border:0 none;cursor:pointer; border-radius:0; background: none; }
button::-moz-focus-inner,input[type="button"]::-moz-focus-inner,input[type="submit"]::-moz-focus-inner,input[type="reset"]::-moz-focus-inner {padding: 0;border: 0 none}
a { color: var(--font-color1);  }
address,
em { font-style:normal }
body,input,select,textarea,button,h1,h2,h3,h4,h5,h6 { font-family:'Roboto','Noto Sans KR', sans-serif; font-size:16px; line-height: 1.55; color: var(--font-color1); text-decoration: none; font-weight:500; letter-spacing: -0.5px;}
body { -webkit-text-size-adjust: none; background:none; }
textarea,select { border:0;  border-radius:0; resize:none; box-shadow:none; -webkit-appearance:none; appearance:none; font-weight: normal;}
a:hover { text-decoration: none;}
form { padding: 0; margin: 0; }
small { font-size: 14px; color:var(--font-color3);}
* {box-sizing: border-box; -webkit-box-sizing:border-box;}
::-ms-clear { display: none;}
select::-ms-expand { display:none; }
a,a:hover,a:active,a:visited,a:focus { text-decoration:none ; }
strong { font-weight: 600;}
b { font-weight: 700;}
* { box-sizing: border-box; }
html { font-size:10px; }
img { max-width:100%; }
button,input,select { vertical-align: middle;}
hr { display:block; width:100%; height:1px; background-color: var(--line-color1); margin:30px 0; padding:0; }

::-webkit-scrollbar { width:4px; height:6px; border-radius:4px; }
::-webkit-scrollbar-thumb { width:4px; border-radius:4px; background: #bbb; }
::-webkit-scrollbar-track { background:transparent; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {-webkit-appearance: none; margin: 0;}
input:-webkit-autofill {-webkit-box-shadow: 0 0 0 1000px white inset; box-shadow: 0 0 0 1000px white inset; }
/* 캘린더 아이콘 숨기기 Webpack */
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-calendar-picker-indicator,
input[type="week"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator { display: none; }
body,html { min-height:100vh; width: 100%;}

/**************************************************************************************************************
	Class
**************************************************************************************************************/
/* flex */
.flex { display: flex !important; }
.flex-wrap{ flex-wrap: wrap !important; }
.flex-grow-0 { flex-grow: 0 !important; }
.flex-center { align-items: center !important; }
.flex-colum {flex-direction: column !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-center { justify-content: center !important; }
.justify-content-end { justify-content: flex-end !important; }
.align-items-center { align-items: center !important; }
.gap5 { gap:5px !important;}
.gap10 { gap:10px !important; }
.gap12 { gap:12px !important; }
.gap15 { gap:15px !important; }
.gap20 { gap:20px !important; }
.gap30 { gap:30px !important; }
.gap40 { gap:40px !important; }
.gap50 { gap:50px !important; }

/* box */
.pd10 { padding: 10px !important; }
.pd20 { padding: 20px !important; }
.pd30 { padding: 30px !important; }

.m10 { margin:10px !important }
.mb0 { margin-bottom:0  !important }
.mb10 { margin-bottom:10px !important }
.mb20 { margin-bottom:20px !important }
.mb30 { margin-bottom:30px !important }
.ml40 { margin-left:40px !important }
.mt10 { margin-top:10px !important; }
.mt15 { margin-top:15px !important; }
.mt20 { margin-top:20px !important; }
.mt25 { margin-top:25px !important; }
.mt30 { margin-top:30px !important; }
.mt35 { margin-top:35px !important; }
.mt40 { margin-top:40px !important; }
.mt70 { margin-top:70px !important; }
.mt150 { margin-top:150px !important; }

/* Text */
.f10 { font-size:10px !important; }
.f11 { font-size:11px !important; }
.f12 { font-size:12px !important; }
.f13 { font-size:13px !important; }
.f14 { font-size:14px !important; }
.f15 { font-size:15px !important; }
.f16 { font-size:16px !important; }
.f20 { font-size:20px !important; }
.f24 { font-size:24px !important; }
.f28 { font-size:28px !important; }
.point-color1 { color:var(--point-color1) !important; }
.point-color2 { color:var(--point-color2) !important; }
.point-color3 { color:var(--point-color3) !important; }
.point-color4 { color:var(--point-color4) !important; }
.state-color1 { color:var(--state-color1) !important; }
.state-color2 { color:var(--state-color2) !important; }
.state-color3 { color:var(--state-color3) !important; }
.state-color4 { color:var(--state-color4) !important; }
.state-color5 { color:var(--state-color5) !important; }
.state-color6 { color:var(--state-color6) !important; }
.state-color7 { color:var(--state-color7) !important; }
.state-color8 { color:var(--state-color8) !important; }
.point-bgColor1 { background-color:var(--point-color1) !important; }
.point-bgColor2 { background-color:var(--point-color2) !important; }
.point-bgColor3 { background-color:var(--point-color3) !important; }
.point-bgColor4 { background-color:var(--point-color4) !important; }
.state-bgColor1 { background-color:var(--state-color1) !important; }
.state-bgColor2 { background-color:var(--state-color2) !important; }
.state-bgColor3 { background-color:var(--state-color3) !important; }
.state-bgColor4 { background-color:var(--state-color4) !important; }
.state-bgColor5 { background-color:var(--state-color5) !important; }
.state-bgColor6 { background-color:var(--state-color6) !important; }
.state-bgColor7 { background-color:var(--state-color7) !important; }
.state-bgColor8 { background-color:var(--state-color8) !important; }
.font-color1 { color:var(--font-color1) !important; }
.font-color2 { color:var(--font-color2) !important; }
.font-color3 { color:var(--font-color3) !important; }
.font-color4 { color:var(--font-color4) !important; }
.line-color1 { border-color:var(--line-color1) !important; }
.line-color2 { border-color:var(--line-color2) !important; }
.line-color3 { border-color:var(--line-color3) !important; }
.txt-white { color:var(--point-color4) !important }
.txt-red { color:var(--state-color5) !important }
.txt-black { color:#111 !important; }
.text-center { text-align:center !important; }
.text-left { text-align:left !important; }
.text-right { text-align:right !important; }
.text-line-through { text-decoration: line-through !important; }
.text500 { font-weight: 500 !important;}
.text700 { font-weight: 700 !important;}
.upright {
	writing-mode: vertical-rl; 	/* 한글 세로로 */
	text-orientation: upright;  /* 영어*/ 
}

/* scale */
.scale15 { scale: 1.5 !important; }
.scale20 { scale: 2.0 !important; }

/* Title */
.h2 { font-size:32px; font-weight:700; color:var(--font-color1); margin-bottom:25px;}
.h3 { font-size:18px; font-weight:700; color:var(--font-color1); margin-bottom:15px;}
.h3 small { display:block; }
.h4 { font-size:16px; font-weight:700; color:var(--font-color2); margin-bottom:12px;}
.h3-desc { font-size:16px; font-weight:700; margin-bottom:12px; }
.h3-desc small { display:block; font-size:13px; color:var(--font-color3); }

/* cuser */
.cuser-pointer { cursor: pointer !important;}


.hidden { position: absolute;left:0; opacity:0; width:0; height: 0; font-size:0; }
/**************************************************************************************************************
	Bootstrap OVERRIDE
**************************************************************************************************************/
.fade { transition: opacity 0.15s linear;}
.modal {  
	--bs-modal-zindex: 1055;
	--bs-modal-width: 500px;
	--bs-modal-width-lg: 1100px;

	--bs-modal-padding: 20px;
	--bs-modal-margin: 10px;
	--bs-modal-color: ;
	--bs-modal-bg: #000;
	--bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
	--bs-modal-header-padding-x: 1rem;
	--bs-modal-header-padding-y: 1rem;
	--bs-modal-header-padding: 1rem 1rem;
	--bs-modal-header-border-color: var(--bs-border-color);
	--bs-modal-header-border-width: var(--bs-border-width);
	--bs-modal-title-line-height: 1.5;
	--bs-modal-footer-gap: 0.5rem;
	--bs-modal-footer-bg: ;
	--bs-modal-footer-border-color: var(--bs-border-color);
	--bs-modal-footer-border-width: var(--bs-border-width);
	position: fixed;  top: 0;  left: 0;  z-index: var(--bs-modal-zindex);  display: none;  width: 100%;  height: 100%;  overflow-x: hidden;  overflow-y: auto;  outline: 0;
}
.modal-dialog { margin:0 auto; position: relative;  width: auto;   pointer-events: none; max-width:var(--bs-modal-width);}
.modal-dialog-lg { margin:0 auto; position: relative;  width: auto;   pointer-events: none; max-width:var(--bs-modal-width-lg);}

.modal.fade .modal-dialog {  transform: translate(0, -50px);}
.modal.show .modal-dialog {  transform: none}
.modal.modal-static .modal-dialog {transform: scale(1.02);}
.modal-dialog-centered {  display: flex;  align-items: center;  min-height: calc(100% - var(--bs-modal-margin) * 2);}
.modal-content {  position: relative;  display: flex;  flex-direction: column;  width: 100%;   pointer-events: auto;  background-color: #fff;  background-clip: padding-box;  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);  border-radius: var(--bs-modal-border-radius);  outline: 0;}
.modal-backdrop {  --bs-backdrop-zindex: 1050;  --bs-backdrop-bg: #000;  --bs-backdrop-opacity: 0.5;  position: fixed;  top: 0;  left: 0;  z-index: var(--bs-backdrop-zindex);  width: 100vw;  height: 100vh;  background-color: var(--bs-backdrop-bg);}
.modal-backdrop.fade {  opacity: 0;}
.modal-backdrop.show {  opacity: var(--bs-backdrop-opacity);}
.modal-header {  display: flex;  flex-shrink: 0;  align-items: center;  justify-content: space-between;  padding:10px 15px;  border-bottom:1px solid var(--line-color1); }
.modal-header .btn-close {  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;}
.modal-header .btn-ico { font-size:25px; color:var(--font-color3); transition: all 0.5s ease;}
.modal-header .btn-ico:hover { transform: translateY(3px); color:var(--point-color1);}
.modal-title {  margin-bottom: 0;  line-height: var(--bs-modal-title-line-height);}
.modal-body {  position: relative;  flex: 1 1 auto;  padding: var(--bs-modal-padding);}
.modal-footer {  display: flex; border-radius:0 0 10px 10px; overflow: hidden; }
.modal-footer button { flex:1; height:55px; font-size:16px; border-left:0 !important; border-bottom:0 !important; border-radius:0 !important }
.modal-footer button:last-child { border-right:0 !important }
.modal-content { border-radius:10px; }
.modal-header { padding: 20px; border-radius:10px 10px 0 0;}
.modal-title { font-size:20px; }
.modal .btn-close { width:24px; height:24px; background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") no-repeat 50% 50%;margin:0; }

/**************************************************************************************************************
	Form
**************************************************************************************************************/
::-webkit-input-placeholder { color:var(--font-color4);}
.inp::-webkit-input-placeholder { color: var(--font-color4)}
.textarea::-webkit-input-placeholder { color: var(--font-color4)}

.inp { height:45px; border-radius:5px; border: 1px solid var(--line-color1); background:var(--background-color1); padding-left: 15px; font-size:15px; -webkit-appearance:none; box-shadow:none; font-weight: 400; transition: all 0.5s ease; outline:none;; }
.inp:disabled { background-color: var(--background-color2); color: var(--font-color3);}
.inp.danger { border-color:var(--point-color2) !important; }
.inp:focus, 
.inp:hover { border-color:var(--line-color3); background:#fff;}
.inp.block { width:100%; }
.inp.small { height: 35px; font-size:13px;  }
.inp.large { height: 56px; font-size:16px;  }
.inp.large-content { width: 100%; height: 350px; font-size:18px; padding: 15px;}

.inp.calendar { background-image: url(../img/common/ico_calendar.png);  background-position: right 10px center; background-repeat: no-repeat;}
.inp.calendar.small { background-size:auto 15px; }
.inp.text-center { padding:0; }
.inp.w300 { width:300px;}
.textarea { width:100%; height:170px; border-radius:5px; border: 1px solid var(--line-color1); padding: 16px; font-size:16px; -webkit-appearance:none; box-shadow:none; font-weight: 400; outline:none; transition: all 0.5s ease;;}
.textarea:disabled { background-color: var(--background-color2); color: var(--font-color3);}
.textarea.danger { border-color:var(--point-color2) !important; }
.textarea:focus { border-color:var(--line-color3) !important; background:#fff; }

.select { height:45px; border-radius:5px; border: 1px solid var(--line-color1); padding-left: 15px; padding-right:30px;  font-size:15px; -webkit-appearance:none; box-shadow:none; font-weight: 400; background: url(../img/common/bu_select.png) no-repeat right 50%; background-position:right 16px center; outline:none; transition: all 0.5s ease;}
.select.block { width:100%; }
.select.large { height: 56px; font-size:16px;  }
.select:disabled { background-color: var(--background-color2); color: var(--font-color3);}
.select.danger { border-color:var(--point-color2) !important; }
.select:focus { border-color:var(--line-color3); background-color:#fff; }
.select.w300 { width:300px; }

.checkbox { position: absolute; z-index:-100; opacity:0;width:0; height: 0; display: none;  }
.checkbox + div { display: inline-flex; align-items: center; }
.checkbox + div em { width:16px; height: 16px; border-radius:4px; background:#fff; position: relative; border: 1px solid var(--line-color1); transition: all 0.5s ease;}
.checkbox + div em:before { content: ''; position: absolute;left: 0; top:0; right:0; bottom:0; background: url(../img/common/checkbox.png) no-repeat 50% 50%; opacity:0; transition: all 0.5s ease; }
.checkbox + div p { font-size:14px; margin-left: 8px; font-weight: 400; }
.checkbox:checked + div em { background: #545871; border-color:#545871; }
.checkbox:checked + div em:before { opacity:1; }
.checkbox:disabled + div em { background: var(--background-color2)}
.checkbox:disabled:checked + div em { background: var(--background-color2); border-color:var(--line-color2); }
.checkbox.blue:checked + div em { background:var(--point-color1); border-color:var(--point-color1);}

.radio { position: absolute; z-index:-100; opacity:0;width:0; height: 0; display: none;  }
.radio + div { display: inline-flex; align-items: center; }
.radio + div em { width:16px; height: 16px; border-radius:100%; background:#fff; position: relative; border: 1px solid var(--line-color1); transition: all 0.5s ease;}
.radio + div em:before { content: ''; position: absolute;left: 3px; top:3px; right:3px; bottom:3px; border-radius:100%; background:var(--point-color1); opacity:0; transition: all 0.5s ease; }
.radio + div p { font-size:14px; margin-left: 8px; font-weight: 400; }
.radio:checked + div em { border-color:var(--point-color1) }
.radio:checked + div em:before { opacity:1; }
.radio:disabled + div em { background: var(--background-color2)}
.radio:disabled:checked + div em { background: var(--background-color2); border-color:var(--line-color2); }

.radio-box { display: inline-flex; padding:10px; border-radius:45px; padding:5px; border:1px solid var(--line-color1); }
.radio-box .radio-txt { position: absolute; z-index:-9999; opacity:0;}
.radio-box .radio-txt + p { cursor: pointer; transition: all 0.5s ease; height:30px; border-radius:30px; width:100px; line-height: 30px; text-align:center; font-size:15px; color:var(--font-color4);}
.radio-box .radio-txt:checked + p { background-color: var(--point-color1); color:#fff; font-weight:600; }

.switch1 { position: absolute;left: 0; opacity:0; width:0; height: 0;}
.switch1 + div { display: flex; align-items: center; gap:8px; cursor: pointer;}
.switch1 + div span { font-size:14px; }
.switch1 + div p { display: inline-block; vertical-align: middle; width:42px; height: 24px;position: relative; background: #ccc; border-radius:24px;  transition: all 0.5s ease; }
.switch1 + div p em { position: absolute; right:2px; top:2px; width:20px; height: 20px; border-radius:100%; background: #fff; transition: all 0.5s ease; display:flex; justify-content: center; align-items: center; color:#999; box-shadow:2px 2px 4px rgba(0,0,0,0.2); }
.switch1 + div p em .xi-check { display:none;}
.switch1:checked + div p { background:var(--point-color1);}
.switch1:checked + div p em { right:20px; background:#fff; color:var(--point-color1) ; }
.switch1:checked + div p em .xi-check { display:inline;}
.switch1:checked + div p em .xi-close { display:none;}

.switch2 { position: relative; left: 0; opacity:0; width:0; height: 0;}
.switch2 + div { display: block; }
.switch2 + div p { display: inline-block; vertical-align: middle; width:42px; height: 24px;position: relative; background: #ccc; border-radius:24px;  transition: all 0.5s ease; }
.switch2 + div p em { position: absolute; right:2px; top:2px; width:20px; height: 20px; border-radius:100%; background: #fff; transition: all 0.5s ease; display:flex; justify-content: center; align-items: center; color:#999; box-shadow:2px 2px 4px rgba(0,0,0,0.2); }
.switch2 + div p em .xi-check { display:none;}
.switch2:checked + div p { background:var(--point-color1);}
.switch2:checked + div p em { right:20px; background:#fff; color:var(--point-color1) ; }
.switch2:checked + div p em .xi-check { display:inline;}
.switch2:checked + div p em .xi-close { display:none;}

/**************************************************************************************************************
	button & Input
**************************************************************************************************************/
.btn { height: 45px; border-radius:5px; padding:0 25px; font-size:15px;   transition: all 0.5s ease; white-space:nowrap}
.btn:hover { box-shadow:4px 4px 12px rgba(0,0,0,0.2); }
.btn:active { transform: translateY(2px) }

.btn.block { width:100%; padding:0;}
.btn:disabled { cursor: no-drop}
.btn.btn-lg { height: 56px; font-size:18px; padding:0 25px;  }
.btn.btn-m { height: 40px; padding:0 20px;  }
.btn.btn-s { height: 32px; padding:0 15px; font-size:12px;  }
.btn.btn-fill-blue { background: var(--point-color1); color: #fff; }
.btn.btn-fill-blue:hover {  }
.btn.btn-fill-blue:disabled { color: var(--font-color4); background:var(--background-color2); }
.btn.btn-fill-blue2 { background: var(--point-color3); color: #fff; }
.btn.btn-fill-blue2:hover {  }
.btn.btn-fill-blue2:disabled { color: var(--font-color4); background:var(--background-color2); }
.btn.btn-fill-blue3 { background: var(--point-color4); color: var(--point-color1); border:1px solid #fff }
.btn.btn-fill-blue3:hover { border-color:var(--point-color1);  }
.btn.btn-fill-blue3:disabled { color: var(--font-color4); background:var(--background-color2); }
.btn.btn-fill-black { background:#111; color:#fff; }
.btn.btn-fill-black:hover {  }
.btn.btn-fill-black:disabled { background:var(--background-color2); color: var(--font-color4);  }
.btn.btn-fill-gray { background:var(--background-color2); color:var(--font-color2); border:1px solid var(--line-color1) }
.btn.btn-fill-gray:hover { border-color:var(--line-color3); color:var(--font-color1); }
.btn.btn-fill-gray:disabled { background:var(--background-color2); color: var(--font-color4);  }
.btn.btn-fill-gray2 { background:#f2f2f2; color:#5d6962; border:1px solid var(--background-color2) }
.btn.btn-fill-gray2:hover {  }
.btn.btn-fill-gray2:disabled { background:var(--background-color2); color: var(--font-color4);  }
.btn.btn-fill-gray3 { background:var(--background-color2); color:var(--font-color2); border:1px solid var(--line-color1); border-radius: 10px;}
.btn.btn-fill-gray3:active { background:var(--background-color4); color:var(--font-color2); border:1px solid var(--line-color1) }
.btn.btn-fill-red { background:var(--state-color5); color: #fff; border:1px solid #fff; border-radius: 10px;}
.btn.btn-fill-red:active { background:var(--background-color6); color:var(--font-color2); border:1px solid #fff }
.btn.btn-fill-red:disabled { color: var(--font-color4); background:var(--background-color2); }
.btn.btn-fill-red2 { background:var(--background-color6); color:var(--state-color5); border:1px solid #fff; border-radius: 10px;}
.btn.btn-fill-red2:hover { border-color: var(--state-color5);}
.btn.btn-fill-red2:active { background:var(--background-color6); color:var(--font-color2); border:1px solid var(--state-color5); }
.btn.btn-fill-red2:disabled { color: var(--font-color4); background:var(--background-color2); }
.btn.btn-line-blue { border:1px solid var(--point-color1); color:var(--point-color1); }
.btn.btn-line-blue:hover {  }
.btn.btn-line-blue:disabled { color: var(--font-color4); background:var(--background-color2); border: 0;  }
.btn.btn-line-blue2 { border:1px solid var(--point-color3); color:var(--point-color3) }
.btn.btn-line-blue2:hover {  }
.btn.btn-line-blue2:disabled { color: var(--font-color4); background:var(--background-color2); border: 0;  }
.btn.btn-line-black { border:1px solid #000;  }
.btn.btn-line-black:hover { background:#000; color:#fff; }
.btn.btn-line-black:disabled { color: var(--font-color4); background:var(--background-color2); border: 0;}
.btn.btn-line-gray { border:1px solid var(--line-color1);  color:var(--font-color3);  }
.btn.btn-line-gray:hover { background:#eee; }

.btn-round { border-radius:56px; }
.btn.btn-icon { border:1px solid var(--line-color1);  }
.btn.btn-icon:hover { background:#eee }
.btn.btn-icon:disabled { color: var(--font-color4); background:var(--background-color2); border: 0;}
.btn i { vertical-align: middle; margin:-3px 4px 0;}
.btn i:first-child { margin-left:0;}
.btn i:last-child { margin-right:0;}

.btn-container { display: table-cell; vertical-align: middle; text-align: center;}
.btn-color-mode-switch { display: inline-block; margin: 0px; position: relative; min-width: 220px;}
.btn-color-mode-switch > label.btn-color-mode-switch-inner { margin: 0px; height: 45px; border-radius: 26px; overflow: hidden; position: relative; transition: all 0.3s ease; display: block; white-space: nowrap; padding: 0px;  }
.btn-color-mode-switch > label.btn-color-mode-switch-inner:before { content: attr(data-on); position: absolute; font-size: 16px; font-weight: 600; top: 50%; right: 25px; color: #999; transition: all 0.3s ease; white-space: nowrap; transform: translateY(-50%); }
.btn-color-mode-switch > label.btn-color-mode-switch-inner .switch-after { background: #de70a7; color: white; border-radius: 26px; position: absolute; font-size: 16px; display: flex; justify-content: center; align-items: center; text-align: center; transition: all 0.3s ease; box-shadow: 0px 0px 6px -2px #111; padding: 5px 20px; font-weight: 600; white-space: nowrap; min-width: 100px; top: 50%; transform: translateY(-50%); }
.btn-color-mode-switch input[type="checkbox"] { cursor: pointer; width: 100%; height: 100%; opacity: 0; position: absolute; top: 0; z-index: 1; margin: 0px; }
.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner { background-color: #F0F6FF; }
.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner .switch-after { background: #4C90F6; color: white; left: calc(95% - 95px); }
.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before { content: attr(data-off); right: auto; bottom: 3px; left: 25px; }
.btn-color-mode-switch input[type="checkbox"]:not(:checked) + label.btn-color-mode-switch-inner .switch-after { left: calc(5% - 5px); }
.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before { color: #999; }

.ico-excel { display: inline-block; vertical-align: middle; width:21px; height:21px; background: url(../img/common/ico_excel.png) no-repeat  0 0;}
.ico-qrcode { display: inline-block; vertical-align: middle; width:21px; height:21px; background: url(../img/common/ico_qrcode.png) no-repeat  0 0;}
.ico-upload { display: inline-block; vertical-align: middle; width:8px; height:10px; background: url(../img/admin/ico_upload.png) no-repeat  0 0;}
.ico-download { display: inline-block; vertical-align: middle; width:8px; height:10px; background: url(../img/admin/ico_download.png) no-repeat  0 0;}
.tit-excel { font-size:13px;  }
.tit-excel i { margin:-3px 5px 0 0;}

.form-tit { font-size:15px; margin-bottom:5px;}
.form-tit-center { font-size:15px; margin-bottom:5px; float: left;}


.form-div { display:flex; gap:10px; margin-bottom:25px;}
.form-div div{ height:45px; border-radius:5px; border:1px solid; border-color:#ddd; padding:10px; width:100%;}

.form-validation { display:flex; gap:15px; align-items: center; margin:10px 0 25px;}

.form-validation span { }
.form-validation .bar { flex:1; height:8px; position:relative; border-radius:8px; overflow:hidden; background-color: var(--background-color2);}
.form-validation .bar:before { content: ''; position: absolute; left: 0; top:0; height:100%; border-radius:8px; background-color: #000;}
.form-validation .bar.step1:before { width:25%; background-color: #FF5353;}
.form-validation .bar.step2:before { width:50%; background-color: #FF5353;}
.form-validation .bar.step3:before { width:75%; background-color: #FB8110;}
.form-validation .bar.step4:before { width:100%; background-color: #4ECB71; }
.form-validation .bar.step1 + strong { color:#FF5353; }
.form-validation .bar.step2 + strong { color:#FF5353; }
.form-validation .bar.step3 + strong { color:#FB8110; }
.form-validation .bar.step4 + strong { color:#4ECB71; }
.form-validation strong { font-weight:700; }
.inp-box { margin-bottom:25px;  position: relative;}
.inp-box .in { padding-right: 50px;}
.inp-btn-box { display:flex; gap:10px; }
.inp-btn-box .btn { white-space: nowrap;}
.inp-btn-box .time {  position:absolute; right:110px; top:0; bottom:0; display: flex; align-items: center; color:var(--font-color3); }
.inp-btn-box .time + .inp { padding-right:100px; }
.inp-btn-box .nowrap { width:190px; }
.inp-box .btn-search { position:absolute; right:0; top:0; height:100%; width:45px; border:0; outline:none; background: url(../img/common/ico_search.png) no-repeat 50% 50%;}
.inp-box .btn-search + .inp { padding-right:50px; }
.inp-box .inside { position:absolute; right:calc(40% + 15px); top:0; line-height:45px; font-size:15px; color:var(--font-color4);}
.inp-box .right { position:absolute; right:15px; top:0; line-height:45px; font-size:15px; color:var(--font-color4);}
.inp-box .right + .inp { text-align:right; padding-right:60px; }
.inp-box .inp-icon { position:absolute; right:20px; top:50%; transform: translateY(-50%); font-size:22px; color:var(--font-color4); cursor: pointer;}
.inp-box .inp-icon .xi-eye-off { display:none; }
.inp-box .inp-icon.active .xi-eye { display:none; }
.inp-box .inp-icon.active .xi-eye-off { display:inline; }

/**************************************************************************************************************
	animation
**************************************************************************************************************/
/* mount animation */
@keyframes smoothUp {
	from {
		opacity: 0;
		transform: translateY(5%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes smoothDown {
	from {
		opacity: 0;
		transform: translateY(-5%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.aniSmoothUp {
	animation: smoothUp 0.5s;
}

.aniSmoothDown {
	animation: smoothDown 0.5s;
}

/* hover animation */
@keyframes hover {
	from {transform: translateY(-3);}
	to {transform: translateY(0);}
}
/**************************************************************************************************************
	Tooth
**************************************************************************************************************/
.tooth { margin-bottom:10px; position: relative; cursor: pointer;}
.tooth svg { vertical-align: top; position: relative; z-index:2;}
.tooth.small { width:72px; margin:0 auto; }
.tooth.small svg { width:100%; height:auto }
.tooth.small .selected p { font-size:13px; }
.tooth.mini { width:30px;}
.tooth.mini svg { width:100%; height:auto }
.tooth.mini .selected p { font-size:10px; }
.tooth-choice-wrap { width:1100px; margin: 0 auto;}
.tooth-strike  { margin-bottom:20px; text-align: center; }
.tooth-strike .in-box { display:inline-block; vertical-align: top; position:relative;  }
.tooth-strike .rounder-box { position: absolute; left:-10px; top:-5px; height:80px; background-color: #eeeeee; border:1px solid #545871; border-radius:10px; }
.tooth-strike g { cursor: pointer; position: relative; z-index:2;}
.tooth-strike .viewer { cursor: default; }
.tooth-strike path { transition: all 0.5s ease;}
.tooth-strike path.cP.active{ fill:#c8e5a1; }
.tooth-strike path.cC.active{ fill:#f9c5f2; }
.tooth-strike path.cN.active{ fill:#89B5F9; }
.tooth-strike path.cI.active{ fill:#babbe6; }
.tooth-strike path.cX.active{ fill:#ff8696; }
.tooth-strike g text { pointer-events: none;}
.tooth-strike .bridge-box button { position:absolute; left:0; top:0; width:24px; height:24px; border-radius:100%; background-color: #545871; z-index:10; display: none;}
.tooth-strike .bridge-box button:before { content:''; position: absolute; left: 50%; top:50%; width:14px; height:2px; transform: translate(-50%,-50%);  background:#fff;}
.tooth-strike .bridge-box button.btn-refresh:before { left:0; top:0; transform: translate(0); width:100%; height: 100%; background: url(../img/common/ico_re.png) no-repeat 50% 50%;}

.tooth .selected { position: absolute; left: 0; right:0; top:0; height: 100%;; display: flex; flex-direction: column ; z-index:3; }
.tooth .selected p { flex:1; font-size:16px; text-align: center; font-weight: 600; display: flex; align-items: center; justify-content: center;} 
.tooth g.on path { fill:#FFEDC8; }

.add-list { margin-top:15px; height:182px; overflow:auto; }
.add-list.auto { height:auto; }
.add-list ul li { margin-bottom:5px;  border-radius:5px; background-color: var(--background-color3); padding:10px 40px 10px 10px; position:relative;text-align:left; }
.add-list ul li .file { font-size:14px; display: block; word-break: break-all; }
.add-list ul li .btn { position:absolute; right:0; top:50%; transform: translateY(-50%); width:40px; height:40px;padding:0; transition: all 0.5s ease;  }
.add-list ul li .btn:hover { }

.langs { }
.langs ul { display:flex; align-items:center; margin-bottom:12px; } 
.langs ul li{ padding-right:10px; margin-right:11px; position:relative; }
.langs ul li:before{ content:''; position:absolute; right:0; top:2px; bottom:2px; width:1px; background-color: var(--line-color1);}
.langs ul li:last-child:before { display:none; }
.langs ul li button { font-size:14px;color:#CACACA;position:relative;  }
.langs ul li button:after{ content:''; position:absolute; left:0; right:0; bottom:-2px; height:2px; background:#000; opacity:0; transition:all 0.5s ease;}
.langs ul li button:before { content:''; display:inline-block; vertical-align: middle; background: url(../img/admin/ico_kor.png) no-repeat  0 0 ; width:20px; height:15px; margin:-3px 4px 0 0;}
.langs ul li button.kor:before { background-image: url(../img/admin/ico_kor.png) }
.langs ul li button.usa:before { background-image: url(../img/admin/ico_usa.png) }
.langs ul li button.ger:before { background-image: url(../img/admin/ico_ger.png) }
.langs ul li button.uk:before { background-image: url(../img/admin/ico_uk.png) }
.langs ul li button.jpn:before { background-image: url(../img/admin/ico_jpn.png) }
.langs ul li button.can:before { background-image: url(../img/admin/ico_can.png) }
.langs ul li.active button { color:var(--font-color1)}
.langs ul li.active button:after { opacity:1;}

.request-search {border-radius:10px; border:1px solid var(--line-color4); margin-bottom:20px;}
.request-search .search-box {padding:10px 15px;}
.request-search .search-box .item {display: inline-block; min-width: 200px; vertical-align: middle; margin:10px 30px 10px 0;}
.request-search .search-box .item dl { display:flex; align-items: center; height: 100%;}
.request-search .search-box .item dl dt { font-size:14px; margin-right:20px;}
.request-search .search-box .item dl dd { flex:1 1 auto;}
.request-search .search-box .item dl dd + dd { margin-left: 10px; }
.request-search .search-box .item dl dd .inp { width:100%; }

.period-box { display: flex; align-items: center;}
.period-box span { padding: 0 6px; }
.period-box .react-calendar { position: absolute; left: 0; top: 8px; width: 100%; border: 1px solid var(--line-color3);}

.board-write { border-top:2px solid #000;  margin-bottom: 30px; padding-bottom: 20px;}
.board-write table { width:100%; border-collapse: collapse;}
.board-write table tbody th { font-size:16px; font-weight:500; border-bottom: 1px solid var(--line-color1); text-align: right; padding-right:20px; }
.board-write table tbody td { padding:8px 0; border-bottom: 1px solid var(--line-color1); }

.board-write.no-border { border:0; }
.board-write .h3 { margin-bottom:0; height:50px; border-bottom:1px solid var(--line-color4); background-color: var(--background-color3); font-size:16px; font-weight:600; line-height:50px; padding-left:20px; color:var(--font-color1); }
.board-write { border-top:0; border:1px solid var(--line-color4); border-radius:10px; overflow: hidden;}
.board-write table tbody th { font-size:14px; border-width:0; vertical-align: middle;}
.board-write table tbody td { font-size:14px; border-width:0; padding-right: 29px;}
.board-write table tbody td .inp,
.board-write table tbody td .select { width:60%; }
.board-write table tbody td .flex {flex: 1; display: flex; flex-direction: row; justify-content: flex-start; margin-top: auto;}
.board-write .table1 thead th { border-width:1px;}
.board-write .table1 tbody td { border-width:1px;}

.inquiry-head { display:flex; justify-content: center; position:relative; margin:20px 0 20px; ; }
.inquiry-head .tabs5 { display: inline-flex; padding:5px; border-radius:5px; border:1px solid var(--line-color1); }
.inquiry-head .tabs5 button { width:180px; height:44px; font-size:15px; color:var(--font-color4); border-radius:5px; transition: all 0.5s ease; }
.inquiry-head .tabs5 button:hover { background-color: var(--background-color3);}
.inquiry-head .tabs5 button.active { background-color: var(--point-color1); color:#fff;font-weight:700; }
.inquiry-head > .flex{ align-items: center;}

.inquiry-list ul { display:flex; border:1px solid var(--line-color4); border-radius:10px; box-shadow:5px 5px 20px rgba(0,0,0,0.1);  overflow:hidden; }
.inquiry-list ul li { flex:1; border-left:1px solid #eee }
.inquiry-list ul li.today { background:var(--background-color4) }
.inquiry-list ul li .head { text-align: center; padding:15px 0; border-bottom: 1px solid var(--line-color4); margin-bottom: 20px; background:var(--background-color4); }
.inquiry-list ul li .head h3 { font-size:21px; font-weight:700; line-height:25px;  }
.inquiry-list ul li .head .month { font-size:13px; color:var(--point-color1); margin:5px 0 4px ;}
.inquiry-list ul li .head .day  { font-size:15px; }
.inquiry-list ul li.today .head h3 { font-size:20px; color:var(--point-color1); }
.inquiry-list ul li .body { height:calc(100vh - 430px); padding:0 10px; overflow: auto;}

.inquiry-box { border:1px solid var(--line-color4); border-radius:10px; margin-bottom: 30px;}
.inquiry-box.pd20 { padding: 20px;}
.inquiry-box div .btn-close { width:20px; height:20px; background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") no-repeat 50% 50%;margin:0; }

.order-item { min-height:40px; border-radius:10px; border:1px solid var(--line-color4); padding:10px; box-shadow:3px 3px 7px rgba(0,0,0,0.05); position: relative; transition: all 0.5s ease; margin-bottom:10px; background-color: #fff; overflow:hidden }
.order-item:hover { border-color:var(--point-color1); box-shadow:3px 3px 15px rgba(0,0,0,0.2);}
.order-item:before { content:''; position:absolute; left:-1px; right:-1px; top:-1px; bottom:-1px; border-radius:10px; border:2px solid var(--point-color1); opacity:0; visibility: hidden; transition: all 0.5s ease;}
.order-item .title { display:flex; justify-content: space-between; font-size:12px;  margin-bottom:8px; } 
.order-item .title span { color:var(--font-color4); text-align:right; }
.order-item .user { display:flex; justify-content: space-between; align-items: center; margin-bottom:8px; }
.order-item .user .name { font-size:15px; font-weight:700; overflow: hidden; white-space: nowrap;}
.order-item .user .type { font-size:15px; text-align: center; min-width: 45px; background-color: lavender; border-radius: 5px; margin-left: 5px; padding: 0 3px;}
.order-item .user .type strong { font-size:14px; font-weight: 500;}
.order-item .user .date { text-align:center; min-width: 75px;}
.order-item .user .date strong { font-size:17px; }
.order-item .order { text-align: right; padding-top:7px; }
.order-item .order a { font-size:13px; color:var(--font-color3); }
.order-item .order a i{ vertical-align: middle; margin:-3px 0 0 3px; }
.order-item .before { cursor: pointer;}
.order-item .detail { display:none; margin:0 -10px -10px; padding:10px; }
.order-item .detail > div { border-top:1px solid var(--line-color4); }
.order-item .detail ul { display:block; border:0; border-radius:0; box-shadow:none; margin:0; height:auto; padding-top:8px; }
.order-item .detail ul li { width:auto; padding:0;  border:0; margin-bottom:12px; }  
.order-item .detail  .btn-detail { width:calc(100% + 20px); margin-left:-10px; margin-bottom:-10px; height:37px; background:#89B5F9; text-align: right; font-size:12px; font-weight:600; padding-right:10px;  color:#fff; transition: all 0.5s ease;}
.order-item .detail  .btn-detail:hover { background-color: var(--point-color1);}
.order-item .tags { margin-top:15px; }

.options-wrap { padding: 0 20px; width: 100%;}
.options-wrap .optionBox { margin-bottom: 10px; display: flex;}
.options-wrap .optionBox ul { display: flex; position: relative;}
.options-wrap .optionBox ul li {display: inline-block; margin-right: 10px;}
.options-wrap .optionBox ul li button {padding: 0 15px; height: 45px; border: 1px solid var(--line-color1); border-radius: 5px; color: var(--font-color3); font-size: 14px; background-color: var(--background-color1);}
.options-wrap .optionBox ul li button.active { background-color: var(--point-color1); color: #fff;}
.options-wrap .optionBox ul li button:hover {transform : translate(-1px, -1px); box-shadow: 1px 1px 5px var(--line-color1);}
.options-wrap .optionBox ul li button:active {transform : translate(0px, 0px);}

.options-wrap .optionBoxHead { margin-bottom: 10px; display: flex;}
.options-wrap .optionBoxHead ul { display: flex; position: relative;}
.options-wrap .optionBoxHead ul li {display: inline-block; margin-right: 10px;}
.options-wrap .optionBoxHead ul li button {transition:all 0.2s; padding: 0 15px; height: 45px; border: 1px solid mediumslateblue; border-radius: 5px; font-size: 14px; font-weight: 700; }
.options-wrap .optionBoxHead ul li button.active { font-weight: 700; background-color: royalblue; color: #fff;}
.options-wrap .optionBoxHead ul li button:hover {transform : translate(-1px, -1px); box-shadow: 1px 1px 5px mediumslateblue;}
.options-wrap .optionBoxHead ul li button:active {transform : translate(0px, 0px);}

.options-wrap .selectBox .row { margin: 10px; }
.options-wrap .selectBox .row ul {display: flex; max-height: 35px;}
.options-wrap .selectBox .row ul li { display: inline-block; }
.options-wrap .selectBox .row ul li.title { width: 100px; vertical-align: middle; }
.options-wrap .selectBox .row ul li button {line-height: 35px; width: 100px; border: 1px solid var(--line-color1); border-radius: 5px; color: var(--font-color3); font-size: 14px;}
.options-wrap .selectBox .row ul li button.active { font-weight: 700; transition:all 0.5s; background-color: var(--point-color1); color: #fff;}

.btn i { transition: all 0.5s ease; }
.btn.active .xi-angle-down { transform: rotate(180deg); }

/* React Calender CSS OVERRIDE */
.react-calendar { position: absolute; left: 0; top:42px;}
.modal-body .react-calendar { position: absolute; left: 0; top:0px; width: 100%; border: 0px;}

.status-box {border:1px solid; border-radius: 5px; width: 100px;}

/**************************************************************************************************************
	table
**************************************************************************************************************/
.table1 { margin-bottom:20px; overflow: auto; animation: smoothDown 0.7s;}
.table1 table { width:100%; border-collapse: separate; border-spacing: 0; white-space: nowrap; }
.table1 table thead th { padding:10px 15px; vertical-align: middle; border-top:1px solid var(--line-color1); border-bottom: 1px solid var(--line-color1); background-color: var(--background-color2); font-weight: 700;}
.table1 table thead tr:first-child {position: sticky; top: 0; z-index: 1;}
.table1 table tbody td { padding:10px 15px; vertical-align: middle; border-bottom: 1px solid var(--line-color1); color:var(--font-color3); text-align: center;transition: all 0.5s ease;}
.table1 table tbody tr {  transition: all 0.5s ease;}
.table1 table tbody tr:nth-child(odd) { background-color: #F9FAFD;}
.table1.hover-type table tbody tr { cursor: pointer;}
.table1.hover-type table tbody tr:hover,
.table1 table tbody tr.active { background-color: var(--background-color4);}
.table1.hover-type table tbody tr:hover td,
.table1 table tbody tr.active td { color:#111;}
.table1 table tbody td.chk { background-color: #F6F7FC; font-size:16px; color:#4ECB71;}
.table1 tr.disabled { opacity:0.5; }

.table-status { margin-bottom:20px; overflow: auto; animation: smoothDown 0.7s; font-size: 16px;}
.table-status table { width:100%; border-collapse: separate; border-spacing: 0; white-space: nowrap; background-color: #fff;}
.table-status table thead th { padding:10px 15px; border-top:1px solid var(--line-color1); border-bottom: 1px solid var(--line-color1); background-color: var(--background-color2); font-weight: 700;}
.table-status table thead tr:first-child {position: sticky; top: 0; z-index: 1;}
.table-status table tbody td { padding:10px 15px; border-bottom: 1px solid var(--line-color1); color:var(--font-color3); text-align: center;transition: all 0.5s ease;}
.table-status table tbody tr { transition: all 0.5s ease;}
.table-status table tbody tr.active { background-color: var(--background-color4);}
.table-status.hover-type table tbody tr:hover td,
.table-status table tbody tr.active td { color:#111;}
.table-status table tbody td.chk { background-color: #F6F7FC; font-size:16px; color:#4ECB71;}
.table-status tr.disabled { opacity:0.5; }

.table-statistics { margin-bottom:20px; overflow: auto; animation: smoothDown 0.7s; font-size: 16px;}
.table-statistics table { width:100%; border-collapse: separate; border-spacing: 0; white-space: nowrap; background-color: #fff;}
.table-statistics table thead th { padding:10px 15px; border-top:1px solid var(--point-color1); border-bottom: 1px solid var(--point-color1); background-color: var(--background-color4); font-weight: 700; color: var(--state-color4);}
.table-statistics table thead tr:first-child {position: sticky; top: 0; z-index: 1;}
.table-statistics table tbody td { padding:10px 15px; border-bottom: 1px solid var(--line-color1); color:var(--font-color3); text-align: center;transition: all 0.5s ease;}
.table-statistics table tbody tr { transition: all 0.5s ease;}
.table-statistics table tbody tr.active { background-color: var(--background-color4);}
.table-statistics.hover-type table tbody tr:hover td,
.table-statistics table tbody tr.active td { color:#111;}
.table-statistics table tbody td.chk { background-color: #F6F7FC; font-size:16px; color:#4ECB71;}
.table-statistics tr.disabled { opacity:0.5; }

.table-manage { margin-bottom:20px; overflow: auto; animation: smoothDown 0.7s; height: 55vh;}
.table-manage table { width:100%; border-collapse: separate; border-spacing: 0; white-space: nowrap; }
.table-manage table thead th { padding:10px 15px; border-top:1px solid var(--line-color1); border-bottom: 1px solid var(--line-color1); background-color: var(--background-color2); font-weight: 700;}
.table-manage table thead tr:first-child {position: sticky; top: 0; z-index: 1;}
.table-manage table tbody td { padding:10px 15px; border-bottom: 1px solid var(--line-color1); color:var(--font-color3); text-align: center;transition: all 0.5s ease;}
.table-manage table tbody tr {  transition: all 0.5s ease;}
.table-manage table tbody tr:nth-child(odd) { background-color: #F9FAFD;}
.table-manage.hover-type table tbody tr { cursor: pointer;}
.table-manage.hover-type table tbody tr:hover,
.table-manage table tbody tr.active { background-color: var(--background-color4);}
.table-manage.hover-type table tbody tr:hover td,
.table-manage table tbody tr.active td { color:#111;}
.table-manage table tbody td.chk { background-color: #F6F7FC; font-size:16px; color:#4ECB71;}
.table-manage tr.disabled { opacity:0.5; }

.table2 { margin-bottom:20px; overflow: auto; animation: smoothDown 0.7s;}
.table2 table { width:100%; border-collapse: separate; border-spacing: 0; white-space: nowrap; }
.table2 table thead th { padding:10px 15px; vertical-align: middle; border-top:1px solid var(--line-color1); border-bottom: 1px solid var(--line-color1); /*background-color: var(--background-color2);*/ font-weight: 700;}
.table2 table thead tr:first-child {position: sticky; top: 0; z-index: 1;}
.table2 table tbody td { padding:10px 15px; vertical-align: middle; border-bottom: 1px solid var(--line-color1); color:var(--font-color3); text-align: center;transition: all 0.5s ease;}
.table2 table tbody tr {  transition: all 0.5s ease;}
/* .table2 table tbody tr:nth-child(odd) { background-color: #F9FAFD;} */
/* .table2.hover-type table tbody tr { cursor: pointer;} */
.table2.hover-type table tbody tr:hover,
.table2 table tbody tr.active { background-color: var(--background-color4);}
.table2.hover-type table tbody tr:hover td,
.table2 table tbody tr.active td { color:#111;}
.table2 table tbody td.chk { background-color: #F6F7FC; font-size:16px; color:#4ECB71;}
.table2 tr.disabled { opacity:0.5; }

.table3 .title {font-weight: 700; color: var(--font-color1); margin-bottom: 15px; text-align: center;}
.table3 table { table-layout:fixed; font-size: 14px !important; width:100%; border-collapse: collapse;}
.table3 table thead th { padding:5px; border-top:1px solid var(--line-color1); border-bottom: 1px solid var(--line-color1); border-right: 1px solid var(--line-color1); background-color: var(--background-color3) !important; }
.table3 table thead th:last-child { border-right: 0px;}
.table3 table tbody td { padding:5px; border-top:1px solid var(--line-color1); border-bottom: 1px solid var(--line-color1); border-right: 1px solid var(--line-color1); color:var(--font-color3); text-align: center; vertical-align: middle; word-break: break-all;}
.table3 table tbody td:last-child { border-right: 0px;}
.table3 table tbody tr:last-child { background-color: var(--background-color3) !important; border-bottom: 0px;}
.table3.hover-type table tbody tr { cursor: pointer;}
.table3.hover-type table tbody tr:hover,
.table3 table tbody tr.active { background-color: var(--background-color4);}
.table3.hover-type table tbody tr:hover td,
.nohover-type table tbody tr:hover{ pointer-events: none;}
.table3 table tbody tr.active td { color:#111;}
.table3 table tbody td.chk { background-color: #F6F7FC; font-size:16px; color:#4ECB71;}
.table3 tr.disabled { opacity:0.5; }
.table3 table .add-textarea {vertical-align: middle; text-align: center; resize: none; word-break: break-word; width: 100%; border: 0px; outline: none; background-color:var(--background-color6); color: var(--font-color3); text-align: center; font-size: 10px !important;}
.table3 table .adjust {background-color: var(--background-color5);}
.table3 table .add {background-color: var(--background-color2);}
.table3 table .add:hover {background-color: var(--background-color6); cursor: pointer; font-weight: 800; transition: all 0.3s ease;}

/**************************************************************************************************************
	tabs
**************************************************************************************************************/
.tabs { margin-bottom:-1px; position: relative; z-index:2; }
.tabs button { float:left; padding:0 25px; height:50px; font-size:1.7rem; color:var(--font-color4); border-radius:5px 5px 0 0; background-color: var(--background-color5); border:1px solid var(--line-color4); margin-right:-1px;}
.tabs button.active { position:relative; background-color: var(--background-color2); color:var(--font-color2); font-weight: 700; box-shadow:5px 0 5px rgba(0,0,0,0.05); border-bottom:0;} 

.tabs1 { display:flex;}
.tabs1 button { height:49px; padding:0 25px; font-size:16px; color:var(--font-color4);position:relative; transition: all 0.5s ease;;}
.tabs1 button:before { content:''; position:absolute; left:0; right:0; bottom:-1px; height:2px; background:#000; transition: all 0.5s ease; opacity:0;}
.tabs1 button.active:before { opacity:1; }
.tabs1 button.active,
.tabs1 button:hover { color:#000;}

.tabs-search { border-radius:0 5px 5px 5px; border:1px solid var(--line-color4); margin-bottom:20px;}
.tabs-search .title { height:50px; border-bottom:1px solid var(--line-color4);background-color:var(--background-color2);  }
.tabs-search .title h4 { line-height:50px; padding-left:20px; font-size:16px; font-weight:600;  }
.tabs-search .search-box { height: 100%; padding:20px;  }
.tabs-search .search-box .item {display:flex; gap:15px; padding-right:100px; }
.tabs-search .search-box .item dl { display:flex; align-items:center; padding:0 6px;  }
.tabs-search .search-box .item dl dt { font-size:14px; margin-right:20px; }
.tabs-search .search-box .item dl dd { flex:1 1 auto; height: 45px;}
.tabs-search .search-box .item .absoulte { position: absolute; z-index: 2;}
.tabs-search .search-box .item dl dd .inp { width:100%; }
.tabs-search .search-box .item + .item { margin-top:12px; }
.tabs-search .btns { text-align:center ; }
.tabs-search .btns button { min-width:100px; }

/**************************************************************************************************************
	grid
**************************************************************************************************************/
.grid { display: grid; gap: 30px; transition: 1s;}

/* status-board gird*/
.grid-status-board { grid-template-rows: repeat(4, minmax(100px, 100px)); grid-template-columns: 1fr 1fr 1fr; gap: 10px; height: 60vh; overflow: auto;}
.grid-status-board .grid-box { height: 100px; display: flex;  font-size: 20px;  transition:all 1s linear;  background-color: var(--background-color6); border : 1px solid #eee; border-radius: 10px; padding: 20px 10px; color: #555;  text-align: center;}
.grid-status-board .grid-box.complete { background-color: #EEF5FF;}
/* employee-board gird*/
.grid-employee-board { grid-template-rows: repeat(4, minmax(150px, 150px)); grid-template-columns: 1fr 1fr 1fr 1fr; gap: 10px; height: 60vh; overflow: auto;}
.grid-employee-board .grid-box { height: 150px; display: flex;  font-size: 20px;  transition:all 1s linear;  border : 1px solid #eee; border-radius: 10px; padding: 10px; color: #555;  text-align: center;}
.grid-employee-board .grid-box:hover { border-color: var(--point-color1);}
.grid-employee-board .grid-box.complete { background-color: #EEF5FF;}

/* request info grid */
.grid-request-info {grid-template-rows: repeat(2, minmax(50px, 100px));grid-template-columns: 1fr 1fr 1fr 1fr; gap: 10px;}
.grid-request-info .grid-item { border-radius: 10px; padding: 10px; position: relative; }
/* .grid-request-info .grid-item:nth-child(7) { grid-column: 400px; grid-template-columns: 300px;} */
.grid-request-info .grid-item:nth-child(4) { grid-column: 4; grid-row: 1/3;}
.grid-request-info .grid-item:nth-child(6) { grid-column: 2/4;}
.grid-request-info .grid-item h4 { font-size: 16px; font-weight: 700;}
.grid-request-info .grid-item img{ padding: 10px; max-width: 100%; height: auto; max-height: 100%;  width: 100%;  position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}

/* request detail item grid */
.grid-detail-item { grid-template-rows: repeat(4, minmax(100px, 100px)); grid-template-columns: 1fr 1fr 1fr; gap: 10px; height: 60vh; overflow: auto;}

/* request list menu grid */
.grid-request-menu {
	grid-template-rows: repeat(2, minmax(50px, 50px));
	grid-template-columns: 1fr 1fr; gap: 5px;
}
.grid-request-menu .grid-box {
	height: 50px;
	font-size: 20px;
	color: #555;  
}

/**************************************************************************************************************
	header user img toggle menu
**************************************************************************************************************/
.menu.active .gnb { opacity:1; top:60px; right: 60px; visibility: visible; }
.gnb { position:absolute; right:0; top:54px; border-radius:10px; box-shadow:5px 5px 15px rgba(0,0,0,0.15); z-index:100; padding:15px; background-color: #fff; min-width:200px;  transition: all 0.5s ease; opacity:0; visibility: hidden;}
.gnb h2 { font-size:16px; font-weight:700; margin-bottom:10px; padding-left:0; line-height: 1; }
.gnb nav a { display:block; line-height:2.2; }
.gnb .etc { padding-top:10px; margin-top:10px; border-top:1px solid var(--line-color4); }
.gnb .etc a { font-size:15px; }
.gnb .etc i { font-size:20px; vertical-align: middle;margin:-3px 5px 0 0;}

/**************************************************************************************************************
	file
**************************************************************************************************************/
/* file dragdrop */
.dragdrop { min-height:inherit; border-radius:10px; border:1px dashed var(--line-color2); background-color: var(--background-color2); text-align: center; padding:15px; font-size:17px; list-style: 1.5; color:var(--font-color3);}
.dragdrop span { display:block;margin:15px auto 0; width:50%; height:45px; line-height:45px; border-radius:5px; background: var(--line-color1); color:var(--font-color2); }
.dragdrop em { font-size:14px; color:var(--font-color4); }
.dragdrop em i { font-style:normal; color:var(--point-color1); text-decoration: underline; cursor: pointer;}
.dragdrop-wrap { padding:15px; border-radius:10px; border:1px solid var(--line-color1); margin-bottom:20px; }
.dragdrop-wrap .row { margin-bottom:0;}
.dragdrop-wrap .col { display:flex; align-items: center; justify-content: center}
.dragdrop-wrap .dragdrop { width:100%; height:100%; display:flex; align-items: center; justify-content: center;}
.dragdrop-wrap .dragdrop .bd-color {border-color: #2F61D5;}
.dragdrop-wrap .dragdrop .box { width:100%; }
.dragdrop-wrap .add-list { width:100%; margin-top:0; }
.dragdrop .tit { margin:15px 0 5px; font-size:12px; color:var(--font-color4)}

/* file preview modal */
.file-body {display: flex; height: 100%; gap: 10px;}
.file-body .file-nav {display: flex; flex-direction: column; max-width: 200px; min-width: 180px;}
.file-body .file-nav .file-box1 {width: 100%; height: 70px; padding-right: 10px;display: flex; justify-content: center; align-items: center;}
.file-body .file-nav .file-box1 label {width: 100%; display: flex; justify-content: center; align-items: center; margin-bottom: 10px;}
.file-body .file-nav .file-box1 input[type="file"] {width: 0; height: 0; opacity: 0;}
.file-body .file-nav .list-wrap {overflow: auto; padding-right: 10px;}
.file-body .file-nav .list-wrap li {display: flex; gap:15px; justify-content: space-between; background-color:var(--background-color3); margin-bottom: 10px; padding: 10px; translate: 1s; border-radius: 5px; cursor:pointer;}
.file-body .file-nav .list-wrap li:hover {background-color: var(--background-color4)}
.file-body .file-nav .list-wrap li span {word-break: break-all;}
.file-body .file-nav .list-wrap li button {color: red; scale: 1.5;}
.file-body .file-nav .list-wrap li.active{background-color:var(--line-color3); color: var(--background-color1);}
.file-body .file-nav .list-wrap li.active button{color: var(--background-color1);}
.file-body .preview {width: 100%; min-width: 300px;border-radius: 10px; background-color: var(--background-color2); overflow: hidden; display: flex; justify-content: center; align-items: center;}
.file-body .preview img {max-width: 100%; height: auto; display: block; max-height: 100%; width: auto; }
.filebox .upload-name { display: inline-block; height: 40px; padding: 0 10px; vertical-align: middle; border: 1px solid #dddddd; width: 78%; color: #999999;}

/**************************************************************************************************************
	request
**************************************************************************************************************/
/* request row side option */
.request-side {position: fixed; right: 20px;}
.request-side ul {display: flex; flex-direction: column; gap: 20px;}
.request-side ul i {scale: 2.0; }
.request-side ul i:hover {color: #4C90F6;}
.request-side .item {transition:all 0.2s linear; border: 1px solid #4C90F6; padding: 10px 15px; border-radius: 10px; box-shadow: 1px 1px 3px #4C90F6; }
.dental-memo {position: absolute; top: 100px; right: 60px; border: 1px solid var(--line-color3); border-radius: 10p; min-width: 300px; padding: 10px; background-color: var(--background-color1);}
.dental-memo textarea {width: 100%; height: 200px; padding: 10px;}

/* request price */
.request-price {width: 100%; padding: 10px; border: 1px solid var(--line-color1); border-radius: 10px;}
.request-price .box {display: flex; gap: 10px; flex-direction: column;}
.request-price .box div {display: flex; align-items: center;}
.request-price .box div h4 {margin: 0px 5px; min-width: 100px;}
.request-price .box div input {width: 100%; min-width: 100px; max-width: 180px; height: 35px; text-align: right; padding-right: 10px;}

/* requestTable row interactive */
.request-option-show {position: relative; border:1px solid; z-index: 1; transition:0.5s linear;}
.request-option-show:active {transform: translate(-300px, 0px); width: 300px; }

/* request file list*/
.request-file-list {display: flex; flex-direction: column; margin: 10px 0px;}
.request-file-list .file-list-wrap {flex: 1; display: flex; align-items: center; gap: 10px; transition: all 0.5s linear;}
.request-file-list .file-list-wrap .item {display: flex; align-items: center; justify-content: center; min-width: 120px; min-height: 80px; border-radius: 5px; text-align: center; cursor: pointer; padding: 0px 5px; background-color: var(--background-color4);}
.request-file-list .file-list-wrap .item:hover, .active {border-bottom: 1px solid var(--point-color1);}
.request-file-list .file-list-wrap .active .detail {display: flex; gap: 10px;}
.request-file-list .file-list-wrap .active .detail div {border-radius: 5px; height: 80px;}
.request-file-list .file-list-wrap .active .detail button {position: relative; padding: 5px; height: 100%; width: 100%;}
.request-file-list .file-list-wrap .active .detail .ocr {position: relative;}
.request-file-list .file-list-wrap .active .detail .ocr .loader {position: absolute; top: 50%; left: 50%; width: 20px; height: 20px; border: 3px solid #fff; border-radius: 50%; border-top: 3px solid transparent; animation: spin 1s linear infinite; transform: translate(-50%, -50%);}
@keyframes spin {
	0% {
	  transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
	  transform: translate(-50%, -50%) rotate(360deg);
	}
}  
.request-file-list .file-list-wrap .active .detail .image:hover {background-color: var(--point-color4); color: var(--point-color1) !important;}
.request-file-list .file-list-wrap .active .detail .ocr:hover { background-color: var(--point-color4); color: var(--point-color1) !important;}
.request-file-list .file-list-wrap .active .detail .delete:hover {background-color: var(--background-color6);}
.request-file-list .file-list-wrap .detail {display: none;}

/* request list tooth coloum */
.request-list-tooth-coloum {display: flex; flex-direction: column; justify-content: space-between; gap: 5px;}
.request-list-tooth-coloum .notation { width: 80px; border-radius: 5px;}


/**************************************************************************************************************
	Chart
**************************************************************************************************************/
.statistics-section { border: 1px solid var(--line-color1); border-radius: 5px; overflow: hidden;}

/* List */
.table.errorlist .counter{text-align: right;}
.table.errorlist .counter span{ background-color: #eee; border-radius: 2px; padding: 1px 5px;}
/* Summaries*/
.table.summaries td{ padding-right: 40px; }
.table.summaries td.critical{ color: #e6614f; }
.table.summaries div.value{ font-size: 40px; margin-top: 10px; }
/* Bar Chart */
.barchart{ font-size: 9px; line-height: 15px; table-layout: fixed; text-align:center;  width: 100%; height:226px; }
.barchart tr:nth-child(1) td{ vertical-align:bottom; height:200px;}
.barchart .bar{ padding: 20px 2px 0; color: white; font-size: 20px; margin: 0 10px;}
.barchart .bar.before{ background: var(--point-color1);}
.barchart .bar.current{ background: blue;}
.barchart .chart-label{ padding: 10px; font-size: 20px; color: var(--point-color1);}

/* criteria */
.criteria-wrap {display: flex; gap: 1px;}
.criteria {padding: 10px 5px; background-color: var(--background-color4); color: var(--font-color3);}
.criteria.regi {border-radius: 5px 0px 0px 5px;}
.criteria.disp {border-radius: 0px 5px 5px 0px;}
.criteria.active {background-color: var(--point-color4); color: var(--point-color1);}

/**************************************************************************************************************
	Status Board
**************************************************************************************************************/
.status-header {height: 10vh; padding: 0 20px; display: flex; justify-content: space-between; align-items: center; background-color: #fff;}
.status-header .side {width: 220px;}
.status-header .logo {width: 200px; cursor: pointer;}
.status-header .title {font-size: 2.5vw; font-weight: 700; color: var(--font-color2); letter-spacing: 10px;}
.status-header .time {font-size: 24px; color: var(--font-color2); float: right;}
.status-header .subject {text-align: center; padding-bottom: 10px; font-size: 18px;}
.status-body {height: 85vh; display: flex; justify-content: space-between; gap: 20px; background-color: var(--background-color2); padding: 20px;}
.status-body .section {flex: 1;}

/**************************************************************************************************************
	Delivery
**************************************************************************************************************/
.delivery-box {border-bottom:1px solid #E3E3E3; border-radius:5px; margin-bottom: 5px; cursor: pointer;}
.delivery-box:hover { border-color: var(--point-color1);}
.order-toggle {padding: 5px; margin-bottom: 5px;}
.order-toggle:hover {box-shadow: 3px 3px 15px rgba(0,0,0,0,2);}
.order-toggle .user .count { font-size:15px; font-weight:700; overflow: hidden; white-space: nowrap;}

/**************************************************************************************************************
	RequestRow
**************************************************************************************************************/

.request-btn-over {
	padding: 0 15px !important;
}
.request-btn-inner-box {
	width: 90px; display: flex; align-items: center; justify-content: start; gap: 10px;
}

/**************************************************************************************************************
	price view for symbol
**************************************************************************************************************/
.price-box {display: flex; justify-content: end;}
.price-box .symbol {text-align: right;}
.price-box .price {text-align: right; margin-right: 15%;}

/**************************************************************************************************************
	payment(invoice)
**************************************************************************************************************/
.payment-wrap { display: flex; margin-bottom: 25px; font-size: 14px !important; }
.payment-wrap div { flex: 1; border: 1px solid #fff; }
.payment-wrap div table { width: 100%; border: 1px solid var(--line-color1);}
.payment-wrap div table tr { padding: 5px; border-bottom:1px sold var(--line-color1); border-top:1px solid var(--line-color1);}
.payment-wrap div table th { padding: 5px; border-right:1px sold var(--line-color1); border-left:1px solid var(--line-color1);}
.payment-wrap div table td { padding: 5px; border-right:1px sold var(--line-color1); border-left:1px solid var(--line-color1);}

.transaction-header {display: flex; padding:10px 0px; justify-content: space-between; margin-bottom: 20px;}
.transaction-header .item {flex: 1; text-align: center;}
.transaction-header .btn-ico {  font-size:30px; color:var(--font-color3); transition: all 0.5s ease;}
.transaction-header .btn-ico:hover { transform: translateY(3px); color:var(--point-color1);}
.transaction-content { width: 210mm; border: 1px solid var(--line-color1); padding: 4mm;}
.transaction-content .item {margin-bottom:25px;}

/**************************************************************************************************************
	Employee and tam
**************************************************************************************************************/
.lab-item {background-color: white; padding: 20px; font-size: 24px; width: 100%; border-bottom: 1px solid var(--line-color1); cursor: pointer;}
.lab-item .lab-name { flex: 1; width: 100%; border: none; font-size: 20px; color: var(--point-color1); font-weight: 700;}
.lab-item:hover,
.lab-name:hover { text-shadow: 0px 0px 1px var(--point-color1); }
.team-wrap {}
.team-item {display: flex; line-height: 45px; padding: 20px; font-size: 18px; width: 100%; min-height: 80px; cursor: pointer;}
.team-item:hover {background-color: var(--background-color2); }
.team-item.active {background-color: var(--background-color2); }
.team-item .team-name { flex: 1; min-width: 100px; width: 70%; border: none; font-size: 18px;}
.team-item.active .team-name { background-color: var(--background-color2);}
.team-item .team-name:focus {border-bottom: 4px solid var(--line-color3); background-color: var(--background-color2);}
.team-item .team-ctrl {flex: 0 0 auto; min-width: 50px;}
.team-delete {padding: 10px; text-align: center; background-color: var(--background-color6); transition: all 0.5s ease; letter-spacing: 5px;}
.team-delete:hover {background-color: var(--state-color5); color: white; cursor: pointer;}

.employee-contents-header {display: flex; justify-content: space-between; border-bottom: 1px solid #ddd; padding: 20px; font-size: 20px;}
.employee-contents-header .add-icon {scale: 2; margin-right: 20px; cursor: pointer;}
.employee-contents-header .add-icon:hover {scale: 2; margin-right: 20px; cursor: pointer; color: var(--point-color1);}
.employee-item-wrap {width: 100%; padding: 10px;}
.employee-item-wrap .employee-item {display: flex; justify-content: space-around; text-align: center; transition: all 0.5s ease;}
.employee-item-wrap .employee-item .employee-profil {
	flex: 0 0 auto; min-width: 100px;
}
.employee-item-wrap .employee-item:hover,
.employee-item-wrap .employee-item .employee-profil:hover{
	color: var(--point-color1);
}

/**************************************************************************************************************
	media
**************************************************************************************************************/
/* btn */
@media screen and (max-width:480px){
	.btn > span{
		display: none;
	}
}
/* tooth scale */
@media screen and (max-width: 1400px) {
	.tooth-choice-wrap {
		width: auto;
		scale: 0.9;
		margin: auto;
	}
}
@media screen and (max-width: 1300px) {
	.tooth-choice-wrap {
		width: auto;
		scale: 0.8;
		margin: auto;
	}
}
@media screen and (max-width: 1200px) {
	.tooth-choice-wrap {
		width: auto;
		scale: 0.7;
		margin: auto;
	}
}
@media screen and (max-width: 1023px) {
	.tooth-choice-wrap {
		width: auto;
		scale: 0.6;
		margin: auto;
	}
}
/* delivery */
@media screen and (max-width: 1023px) {
	.order-toggle .user .count { display: none;}
	.order-item .title {display: none;}
}

@media screen and (max-width:480px){
}
