.form button {
    border: none;
    background: none;
    color: #8b8ba7;
}
.form {
    --timing: 0.3s;
    --width-of-input: 300px;
    --height-of-input: 45px;
    --border-height: 2px;
    --input-bg: #fff;
    --border-color: #2f2ee9;
    --border-radius: 30px;
    --after-border-radius: 1px;
    position: relative;
    width: var(--width-of-input);
    height: var(--height-of-input);
    display: flex;
    align-items: center;
    padding-inline: 0.8em;
    border: 1px solid #d4d4d8;
    border-radius: var(--border-radius);
    transition: border-radius 0.5s ease;
    background: var(--input-bg,#fff);
}
.input {
    font-size: 1.8rem;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding-inline: 0.5em;
    padding-block: 0.7em;
    border: none;
}
.form:before {
    content: "";
    position: absolute;
    background: var(--border-color);
    transform: scaleX(0);
    transform-origin: center;
    width: 100%;
    height: var(--border-height);
    left: 0;
    bottom: 0;
    border-radius: 1px;
    transition: transform var(--timing) ease;
}
.form:focus-within {
    border-radius: var(--after-border-radius);
}
.form:focus-within:before {
    transform: scale(1);
}
.form svg {
    width: 18px;
    margin-top: 4px;
}